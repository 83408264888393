<template>
	<div class="FieldSettings">
		<div class="breadcrumbTop">
			<a-breadcrumb>
				<a-breadcrumb-item @click='$router.back()'>企业设置</a-breadcrumb-item>
				<a-breadcrumb-item><a href="">通讯录可展示字段</a></a-breadcrumb-item>
			</a-breadcrumb>
		</div>
		<div class="count_info" style="">
			<div id="" style="flex: 1;" class="search_form">
				<span class="cq_text_require">*</span><span id="">
					您可以根据企业情况设置通讯录需要展示的字段
				</span>
			</div>
			<a-button type="primary" class="mr12 pointerCancel" @click="editVisible=true">启用更多</a-button>
		</div>
		<div class="FieldSettingsBox">
			<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
				<span>
					&nbsp; &nbsp;
				</span> <span>字段名称</span> <span style="color: #666;">操作</span>
			</div>
			<div class="FieldSettingsBoxTitle" v-show="element.can_sort==-1" v-for="element in select_list"
				:key="element.id">
				<span>
					&nbsp; &nbsp;
				</span>
				<span>
					{{element.name}}
				</span>
				<span v-show="element.can_forbid==1" class="cq_text_require" @click="stop_item(element)">禁用</span>
			</div>
			<!-- <draggable v-model="select_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
				@start="onStart" @end="onEnd">
				<transition-group> -->
					<div  :draggable="true"
                                    @dragstart="dragstart($event, -1, index)"
                                    @dragover="dragover"
                                    @dragenter="dragenter($event, -1, index)"
                                    @dragend="dragend($event, -1, index)"
                                    @drop="drop" v-show="element.can_sort==1" :key='index' class="FieldSettingsBoxTitle" v-for="(element,index) in select_list"
						>
						<span>
							<a-icon type="unordered-list" />
						</span>
						<span>
							{{element.name}}
						</span>
						
						<span v-show="element.can_forbid==1" class='redcolor'
							@click="stop_item(element)">禁用</span>
					</div>
				<!-- </transition-group>
			</draggable> -->
		</div>

		<a-modal v-model:visible="editVisible" width="60%" title="启用更多" @ok="upInfo">
			<p style="margin-bottom: 8px;"> <span class="cq_text_require">*</span>以下是未选择的通讯录可展示字段，可以直接选择您想要添加的字段。</p>
			<a-row>
				<a-col v-for="(item,index) in select_list2" :key='index'  class="gutter-row" :span="4">
					<div @click="setChoose(item.id)"
						:class="chooseList.indexOf(item.id)>-1?'gutter-box chooseItem':'gutter-box'">{{item.name}}
						<!-- <img src="../../image/choose.png" v-if="chooseList.indexOf(item.id)>-1" class="chooseItemactieve"> -->
					</div>
				</a-col>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
	import {
		getBookShowField,
		bookFieldForbid,
		bookFieldStart,
		bookFieldSort,
	} from '@/api/enterprise.js'
	import options from '@/assets/json/city.js'

	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	export default {
		components: {
			
		},
		data() {
			return {
				chooseList: [],
				stop_index: 0,
				stop_approval_index: 0,
				myArray: [],
				editType: 'add',
				company_id: '',
				company_name: '',
				merge_company: '',
				addressLitst: [],
				options,
				search_form: false,
				delVisible: false,
				editVisible: false,
				mergeVisible: false,
				delCompany: false,
				labelColEdit: {
					span: 4
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 12
				},
				formState2: {},
				select_list: [],
				select_list2: [],
			}

		},
		created() {
			this.getIndustry()
			this.getIndustry2()
		},
		methods: {
			dragstart(e, ind) {
			    // e.preventDefault()
			},
			drop(e) {
			    e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
			    e.preventDefault()
			    if (ind != -1) {
			        this.stop_index = ind;
			    }
			    this.stop_approval_index = index
			},
			dragover(e) {
			    e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
			   let list = this.select_list;
			   let move_data = list[index];
			   list.splice(index, 1)
			   list.splice(this.stop_approval_index, 0, move_data)
			   this.select_list = list;
			   this.onEnd()
			
			},
			setChoose(id) {
				if (this.chooseList.indexOf(id) > -1) {
					this.chooseList.splice(this.chooseList.indexOf(id), 1)
				} else {
					this.chooseList.push(id)
				}
			},
			stop_item(item) {
				bookFieldForbid({
					data: {
						id: item.id
					},
					info: true
				}).then(res => {
					this.getIndustry()
				})
			},
			onStart() {
				this.drag = true;
			},
			//拖拽结束事件
			onEnd() {
				let sort = []
				this.select_list.forEach(item => {
					sort.push(item.id)
				})
				bookFieldSort({
					data: {
						sort: sort.join(',')
					},
					info: true
				}).then(res => {
					
				})
			},
			upInfo() {
				if (this.chooseList.length == 0) {
					return false;
				}
				let option = {
					ids: this.chooseList.join(','),
				}
				bookFieldStart({
					data: option,
					info: true
				}).then(res => {
					this.editVisible = false
					this.getIndustry()
				})
			},
			getIndustry() {
				getBookShowField().then(res => {
					this.select_list = res.data.list.data
				})
			},
			getIndustry2() {
				getBookShowField({
					data: {
						status: -1
					}
				}).then(res => {

					this.select_list2 = res.data.list.data
				})
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");

	.FieldSettings {
		padding: 16px;
		background: #fff;
		margin-top: 16px;
		margin-left: 16px;
		margin-right:16px;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.count_info {
		width: 100%;
		display: flex;
		padding-top: 12px;
	}

	/*被拖拽对象的样式*/
	.item {
		padding: 6px;
		background-color: #fdfdfd;
		border: solid 1px #eee;
		margin-bottom: 10px;
		cursor: move;
		width: 100%;
		display: flex;
		align-items: center;

		span:nth-child(1) {
			flex: 1;
		}
	}

	/*选中样式*/
	.chosen {
		border: solid 2px #3089dc !important;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;

	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 1;
			margin-left: 8px;

		}

		span:nth-child(3) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;


	}

	.gutter-box {
		border: 1px solid @cq_line_e;
		color: @cq_text_c6;
		text-align: center;
		margin-right: 12px;
		margin-bottom: 12px;
		border-radius: 4px;
		position: relative;

		padding: 5px 15px 5px 10px;
		height: 32px
	}

	.chooseItem {
		border: 1px solid @cq_color;

		color: @cq_color;

	}

	.chooseItemactieve {
		position: absolute;
		right: -0.9px;
		height: 22px;
		width: 22px;
		bottom: -0.5px;

	}

	.pointerCancel {
		padding: 0 12px;
	}
	.redcolor{
		color:#fff;
	}
	.FieldSettingsBoxTitle:hover {
		.redcolor{
			color:red;
		}
	}
</style>
